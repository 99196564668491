import Button from '../Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRfiContext } from '../../pages/RfiDetails/context/RfiContext';
import ImageSlider from './ImageSlider';
import { FaImage } from 'react-icons/fa';

const RfiDetailsComponent = () => {
  const rfiContextVal = useRfiContext();
  const navigate = useNavigate();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openSlider = (index) => {
    setCurrentImageIndex(index);
    setIsSliderOpen(true);
  };

  return (
    <div className="border border-border rounded-xl bg-navy px-[24px] py-5 mt-5">
      <div className="flex flex-col w-full">
        <div className="flex gap-[22px] w-full">
          <div className="w-full">
            <div className="w-full flex items-center gap-3 font-monasans text-[22px]">
              <Button
                id={rfiContextVal.projectRfi.rfiDetails._id}
                classes="!rounded-full h-[52px] !min-w-0 !w-[52px] flex items-center justify-center border border-border bg-transparent  "
                onClick={() => {
                  navigate(`/view-project/${rfiContextVal.projectRfi.rfiDetails.projectId}`);
                }}
              >
                <span className="icon-back-arrow text-lg"></span>
              </Button>
              <label htmlFor={rfiContextVal.projectRfi.rfiDetails._id}>
                {rfiContextVal.projectRfi.rfiDetails.rfiIdentifier}
              </label>
            </div>
            <div className="my-3 w-full">
              <table className="w-full border-collapse">
                <tbody>
                  <tr>
                    <td className="py-2 pr-4 w-1/6">
                      <label htmlFor="variationName" className="text-white font-poppins">
                        {rfiContextVal.projectRfi.rfiDetails.rfiIdentifier} :
                      </label>
                    </td>
                    <td className="py-2 pl-4 w-1/2 text-sm text-[#A0A0A0] font-thin">
                      {rfiContextVal.projectRfi.rfiDetails.title}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <label htmlFor="variationDescription" className="text-white font-poppins">
                        RFI Description :
                      </label>
                    </td>
                    <td className="py-2 pl-4 w-1/2 text-sm text-[#A0A0A0] font-thin">
                      {rfiContextVal.projectRfi.rfiDetails.description}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <label htmlFor="variationDescription" className="text-white font-poppins">
                        Quantity :
                      </label>
                    </td>
                    <td className="py-2 pl-4 w-1/2 text-sm text-[#A0A0A0] font-thin">
                      {rfiContextVal.projectRfi.rfiDetails.quantity}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2">
                      <label htmlFor="variationDescription" className="text-white font-poppins">
                        Unit :
                      </label>
                    </td>
                    <td className="py-2 pl-4 w-1/2 text-sm text-[#A0A0A0] font-thin">
                      {rfiContextVal.projectRfi.rfiDetails.unit?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="py-2">
                      <label htmlFor="variationDescription" className="text-white font-poppins">
                        Images :
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="p-5 bg-[#0a1029] border border-border rounded-xl">
                        <div className="flex flex-wrap gap-4">
                          {rfiContextVal.projectRfi.rfiDetails?.fullRFIImages &&
                            rfiContextVal.projectRfi.rfiDetails?.fullRFIImages.length > 0 &&
                            rfiContextVal.projectRfi.rfiDetails?.fullRFIImages.map(
                              (image, index) => {
                                const extension = image.split('.').pop().toLowerCase();
                                if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
                                  return (
                                    <div
                                      key={`rfi-image-key-${rfiContextVal.projectRfi.rfiDetails._id}-${image}`}
                                      className="w-1/5 aspect-square cursor-pointer"
                                      onClick={() => openSlider(index)}
                                    >
                                      <img
                                        src={image}
                                        alt={image}
                                        className="w-full h-full object-cover rounded-lg"
                                      />
                                    </div>
                                  );
                                } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
                                  return (
                                    <div
                                      key={`rfi-image-key-${rfiContextVal.projectRfi.rfiDetails._id}-${image}`}
                                      className="w-1/5 aspect-square cursor-pointer"
                                      onClick={() => openSlider(index)}
                                    >
                                      <video
                                        src={image}
                                        alt={`Slide video ${index}`}
                                        className="w-full h-full object-cover rounded-lg"
                                        controls
                                      />
                                    </div>
                                  );
                                }
                              }
                            )}
                        </div>
                        {rfiContextVal.projectRfi.rfiDetails?.fullRFIImages.length === 0 && (
                          <div className="container mx-auto flex flex-col px-4 py-8 justify-center items-center">
                            <FaImage class="text-gray-600 text-5xl mb-4" />
                            <h2 className="text-2xl font-bold mb-4">No Images Available</h2>
                          </div>
                        )}
                      </div>
                      {isSliderOpen && (
                        <ImageSlider
                          images={rfiContextVal.projectRfi.rfiDetails?.fullRFIImages}
                          currentIndex={currentImageIndex}
                          onClose={() => setIsSliderOpen(false)}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RfiDetailsComponent;
