import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getRequest } from '../api/apiRequest';

const fetchProjectRfiList = async (projectId) => {
  const response = await getRequest(`rfi/project/${projectId}`);
  return response;
};

export const useProjectRfiList = () => {
  const { projectId, rfiId } = useParams();

  const {
    data: projectRfi,
    isFetching: isProjectRfiFetching,
    isError,
    error,
    refetch: refetchVariationList,
  } = useQuery({
    queryKey: ['projectRfiList', projectId],
    queryFn: () => fetchProjectRfiList(projectId),
    enabled: !!projectId, // Only run the query if projectId is available,
    cacheTime: 0, // Disable caching
    select: (response) => {
      if (typeof rfiId !== 'undefined') {
        const rfiDetails = response.rfis.find((rif) => rif._id === rfiId);
        return {
          ...response,
          rfiDetails,
        };
      } else {
        return response;
      }
    },
  });

  return {
    projectRfi,
    refetchVariationList,
    isProjectRfiFetching,
    isError,
    error,
  };
};
