import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { showErrorToast } from '../components/Toast';

const ProtectedRoute = () => {
  // const location = useLocation();
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

  if (!token || !user) {
    return <Navigate to="/" replace />;
  }

  // const isSuperAdmin = user.roles.some((role) => role.name === 'super_admin');

  // const adminRoutes = ['/create-project'];

  // if (adminRoutes.includes(location.pathname) && !isSuperAdmin) {
  //   showErrorToast("You don't have access");
  //   return <Navigate to="/dashboard" replace />;
  // }

  return <Outlet />;
};

export default ProtectedRoute;
